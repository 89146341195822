import LegalLayout from "../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../constants/legal/incapacitated.md"

export default function IncapacitatedPage() {
  return (
    <LegalLayout
      description="How to manage the HEY account of someone who is incapacitated or has died."
      headingSmall="How to manage the Ready Five account of someone who is incapacitated
        or has died"
      source={source}
      title="Incapacitated"
    />
  )
}
